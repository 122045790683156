import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {Error, Form, PayButton, Spinner, Success, SuccessText} from './styled'

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentStatus, setPaymentIntentStatus] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    setClientSecret(params.get("payment_intent_secret"));

    const redirectUrl = params.get("redirect_url");
    if (redirectUrl) {
      setRedirectUrl(redirectUrl);
    }
  }, [stripe]);

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    setIsLoading(true);
    stripe.retrievePaymentIntent(clientSecret).then((pi) => {
      setPaymentIntent(pi.paymentIntent);
      if (pi.paymentIntent.status) {
        setPaymentIntentStatus(pi.paymentIntent.status);
      }
      setIsLoading(false);
    });
  }, [clientSecret, stripe])

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setErrorMessage(null);
    setIsLoading(true);

    let options = {
      elements
    }
    if (redirectUrl) {
      options.confirmParams = options.confirmParams
        ? options.confirmParams :
        { return_url: redirectUrl };
    } else {
      options.redirect = "if_required";
    }
    const { paymentIntent, error } = await stripe.confirmPayment(options);

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred");
      }

      setIsLoading(false);
      return;
    }

    setPaymentIntentStatus(paymentIntent.status)
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <>
      <Form status={paymentIntentStatus}>
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" options={paymentElementOptions} />
            <PayButton disabled={isLoading || !stripe || !elements}  id="submit">
              <span id="button-text">
                {isLoading || !paymentIntent
                  ? <Spinner />
                  : `Pay ${paymentIntent.amount/100} ${paymentIntent.currency.toUpperCase()}`
                }
              </span>
            </PayButton>
        </form>
        <Error>{errorMessage}</Error>
      </Form>

      {paymentIntentStatus === 'succeeded' && (
        <>
          <Success />
          <SuccessText>Payment succeeded!</SuccessText>
        </>
      )}
    </>
  );
};