import styled from "styled-components";
import success from "../../image/success.svg";

export const Form = styled.div`
  width: 300px;
  display: ${ props => props.status === 'requires_payment_method' ? 'block' : 'none' }; 
`

export const PayButton = styled.button`
  width: 100%;
  height: 48px;
  
  margin-top: 24px;
  
  font-weight: 600;
  color: white;
  
  background: #3988e5;
  
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 3px 6px rgba(0, 0, 0, 0.05);
  
  cursor: pointer;
`

export const Spinner = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Success = styled.div`
  background-image: url(${success});
  margin:0 auto;
  width: 150px;
  height: 150px;
`

export const SuccessText = styled.p`
  margin:10px auto;
  font-weight: 600;
`

export const Error = styled.div`
  width: 100%;
  height: 48px;
  
  margin-top: 24px;
  
  font-weight: 600;
  color: #D75A4A;
`